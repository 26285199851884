<template>
  <div>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <FormDialog
        :dialog="dialog"
        :title="'Neuen Kunden anlegen'"
        :values="customerCreateFields"
        :successButton="'Erstellen'"
        :icon="'mdi-account-plus'"
        :headLines="[
            {text: 'Kontakt', position: 2},
            {text: 'Partner', position: 4}
            ]"
        :cut="4"
        @hideDialog="hideDialog"
        @emitNewData="createCustomer"
    ></FormDialog>
  </div>
</template>
<script>
import FormDialog from "@/components/dialog/FormDialogNew";
import {mapGetters} from "vuex";
import {msgObj, success} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");

export default {
  name: "CreateCustomer",
  components: {
    FormDialog,
    Message
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
  },
  data() {
    return {
      message: msgObj(),
    }
  },
  props: {
    dialog: Boolean,
    openAfterCreate: {
      type: Boolean,
      default() {
        return false
      }
    },
    customerCreateFields: {
      type: Array,
      default() {
        return [
          {
            title: 'Vorname',
            value: '',
            id: 0,
            type: 'string'
          },
          {
            title: 'Nachname',
            value: '',
            id: 1,
            type: 'string'
          },
          {
            title: 'Telefonnummer',
            value: '',
            id: 2,
            type: 'string'
          },
          {
            title: 'E-Mail Adresse',
            value: '',
            id: 3,
            type: 'string'
          },
          {
            title: 'Partner Vorname',
            value: '',
            id: 4,
            type: 'string'
          },
          {
            title: 'Partner Nachname',
            value: '',
            id: 5,
            type: 'string'
          },
        ]
      }
    }
  },
  mounted() {},
  methods: {
    hideDialog() {
      this.$emit('hideDialog')
    },
    createCustomer(newValues) {
      let newCustomer = this.getCustomerFromInput(newValues)
      this.$store.dispatch('customer/createCustomer', {
        newCustomer,
        uid: this.user.id,
        openAfterCreate: this.openAfterCreate
      }).then(() => {
            this.hideDialog()
            this.message = success('Kunde wurde erfolgreich angelegt.')
            this.$emit('createCustomer',{})
          }
      )

    },
    getCustomerFromInput(newValues) {
      let newCustomer = {
        firstname: '',
        lastname: '',
        partnerFirstname: '',
        partnerLastname: '',
        email: '',
        tel: ''
      }
      for (let value of newValues) {
        switch (value.title) {
          case 'Vorname':
            newCustomer.firstname = value.value
            break;
          case 'Partner Nachname':
            newCustomer.partnerLastname = value.value
            break;
          case 'Partner Vorname':
            newCustomer.partnerFirstname = value.value
            break;
          case 'Nachname':
            newCustomer.lastname = value.value
            break;
          case 'Telefonnummer':
            newCustomer.tel = value.value
            break;
          case 'E-Mail Adresse':
            newCustomer.email = value.value
            break;
        }
      }
      return newCustomer
    },
  },
}
</script>
